import React, { useState } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SubCategoryTile from '../components/subCategoryTile'
import Slider from '../components/slider'
// import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from '../components/seo'
import Breadcrumbs from '../components/breadcrumbs'

export const query = graphql`
query($slug: String!){
    category: strapiCategory(slug: {eq: $slug}) {
      name
      slug
      path
      metaTitle
      metaDescription
      description
      banner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      bannerMobile {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    subCategories: allStrapiCategory(filter: {level: {gte: 2}}) {
      nodes {
        slug
        name
        parentCategory
         image {
           localFile {
             childImageSharp {
               gatsbyImageData
            }
           }
         }
      }
    }
    latest: allStrapiProduct(filter: {new: {eq: true}}) {
      nodes {
        id
        slug
        model
        searchTitle
        url
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    
  }
`

export default function Category({ data, pageContext }) {
  const category = data.category
  const subCategories = data.subCategories.nodes
  const latestProducts = data.latest.nodes
  const [showMore, setShowMore] = useState(false);
  const para = category.description || ""
  const shortened = showMore === true ? para : (para.slice(0, 100) + "...");

  // category 
  let subCategoryData=[]
  {subCategories.map((subCategory,index) => {
    if (subCategory.parentCategory == category.name) {
      
        subCategoryData.push(subCategory)
    }
  })}
  if (subCategoryData.length > 1 && category.name=="Power Tools") {
    // Remove the first matching element and push it to the end
    const firstMatchingElement = subCategoryData.shift();
    subCategoryData.push(firstMatchingElement);
  }

  // const {
  //   breadcrumb: { crumbs },
  // } = pageContext

  return (
    <Layout>
      <SEO 
        title={category.metaTitle}
        description={category.metaDescription}
      />
      <div className="hidden md:block md:h-64 w-full bg-primary">
        <GatsbyImage
          image={getImage(category?.banner?.localFile)}
          alt={category.name}
          className="h-full w-full"
        />
      </div>
      <div className='md:hidden w-full'>
        <GatsbyImage
            image={getImage(category?.bannerMobile?.localFile)}
            alt={category.name}
            className="h-full w-full"
          />
      </div>
      <div className="bg-secondary-light px-4 md:px-12">
        <div className="text-xs md:text-sm pt-5 pb-5 md:pb-9">
          {/* <Breadcrumb
            crumbs={crumbs}
            crumbLabel={category.name}
          /> */}
          <Breadcrumbs 
            trail={[{title: category.name, link: "/" + category.path}]}
          />
        </div>

        <h1 className=' text-lg md:text-xl 2xl:text-2xl text-primary pb-2'>{category.name}</h1>
        <p className="hidden md:block">{para}</p>
        {para.length > 0 &&
          <div>
            <p className='md:hidden'>{shortened}</p>
            <button className="text-sm underline md:hidden pt-2" onClick={() => setShowMore(!showMore)}>{showMore ? "Show Less" : "Read More"}</button>

          </div>
        }
        <div className="py-8 grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-x-6 md:gap-y-10 2xl:gap-x-8 2xl:gap-y-12">

          {subCategoryData.map(subCategory => {
            if (subCategory.parentCategory == category.name) {
              return (<SubCategoryTile item={subCategory} />)
            }
            // return (<SubCategoryTile className="" item={subCategory} />)
          })}
        </div>


      </div>
      <Slider title="Latest Products" products={latestProducts} />
    </Layout>
  )
}
